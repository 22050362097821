body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

/* ::-webkit-scrollbar{
  background: red;
} */
/* ::-webkit-scrollbar {
  display: none;
  } */

  .custom-scrollbar-track {
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 100%;
    background-color: #00000000;
  }
  
  .custom-scrollbar-thumb {
    width: 10px;
    background-color: #888;
    border-radius: 10px;
    position: absolute;
    top: 0;
  }
  
  .custom-scrollbar-thumb:hover {
    background-color: #555;
  }
  
  /* ::-webkit-scrollbar{ */
    /* display: none;
  } */
 .assignReportScroll::-webkit-scrollbar, .sidenav::-webkit-scrollbar {
    width: 10px;
   
  /* height: 20px; */ 
}
.globalScroll::-webkit-scrollbar{
  width: 15px;
}

.scrollHeight::-webkit-scrollbar{
  height:2px;
  
}

.globalScroll::-webkit-scrollbar-track,
.assignReportScroll::-webkit-scrollbar-track ,
.sidenav::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #00000000;
}


.globalScroll::-webkit-scrollbar-thumb ,.assignReportScroll::-webkit-scrollbar-thumb , .sidenav::-webkit-scrollbar-thumb{
  background: #c5c5c5;
  border-radius: 100vh;
  border: 3px solid #f6f7ed;
}

.globalScroll::-webkit-scrollbar-thumb{
  background: #474747;

}

/* .sidenav::-webkit-scrollbar-thumb:hover {
  background: #c5c5c5;
} */


.createReportScroll::-webkit-scrollbar{
  display: none;
}




.libre-caslon-text-regular {
  font-family: "Libre Caslon Text", serif;
  font-weight: 400;
  font-style: normal;
}

.libre-caslon-text-bold {
  font-family: "Libre Caslon Text", serif;
  font-weight: 700;
  font-style: normal;
}

.libre-caslon-text-regular-italic {
  font-family: "Libre Caslon Text", serif;
  font-weight: 400;
  font-style: italic;
}



.alice-regular {
  font-family: "Alice", serif;
  font-style: normal;
}
